<template>
  <HomePage/>
</template>

<script>
import HomePage from '@/components/pages/HomePage';
export default {
  name: 'App',
  components: {
    HomePage,
  }
}
</script>