<template>
    <div>
        <HeaderComponent/>
        <HomeComponent/>
        <FooterComponent/>
        <WrapperComponent/>
        <NewsLetterComponent/>
        
    </div>
</template>

<script>
import HeaderComponent from '@/components/navigation/HeaderComponent';
import HomeComponent from '@/components/home/HomeComponent';
import FooterComponent from '@/components/footer/FooterComponent';
import WrapperComponent from '@/components/wrapper/WrapperComponent';
import NewsLetterComponent from '@/components/newsletter/NewsLetterComponent';
    export default{
        name: 'HomePage',
        components:{
            HeaderComponent,
            HomeComponent,
            FooterComponent,
            WrapperComponent,
            NewsLetterComponent,
        }
    }
</script>